<style scoped lang="scss">
@import "@/sass/colors.scss";
.v-btn--active {
  background-color: $bonsai-blue !important;
}
</style>

<template>
  <form>
    <h1>{{ $t("login.title") }}</h1>

    <v-text-field
      v-model="form.emailOrMobile"
      type="email"
      :label="$t('login.username')"
      persistent-hint
      @keyup.enter.native="submit()"
    />

    <v-text-field
      :label="$t('login.password')"
      v-model="form.password"
      :type="passwordvisible ? 'text' : 'password'"
      autocomplete="password"
      :append-icon="passwordvisible ? 'mdi-eye-off' : 'mdi-eye'"
      @click:append="togglePasswordVisible"
      @keyup.enter.native="submit()"
    ></v-text-field>
    <v-btn
      :loading="loading"
      @click="submit()"
      :disabled="loading"
      color="primary"
      >{{ $t("login.loginbtn") }}</v-btn
    >

    <div style="margin-top: 2em">
      {{ $t("login.forgotpw") }}
      <a @click="forgotPW()">{{ $t("login.forgotpwbtn") }}</a
      ><br />
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      passwordvisible: false,
      form: {
        email: "",
        mobile: "",
        password: "",
      },
      loading: false,
      error: null,
    };
  },
  methods: {
    togglePasswordVisible() {
      this.passwordvisible = !this.passwordvisible;
    },
    async forgotPW() {
      if (!this.form.emailOrMobile) {
        this.$showError(this.$t("login.forgotpwmsg"));
        return;
      }

      this.loading = true;
      await this.$http
        .post("users/forgotPassword", {
          emailOrMobile: this.form.emailOrMobile,
        })
        .then(() => {
          this.$showSuccess(this.$t("login.forgotpwsuccess"));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async submit() {
      const payload = {
        emailOrMobile: this.form.emailOrMobile,
        password: this.form.password,
      };

      payload.emailOrMobile = payload.emailOrMobile.replaceAll(" ", "");

      this.loading = true;

      try {
        const loginResponse = await this.$http.post("auth/login?v=2", payload);
        let multiFactorCode = prompt(
          this.$t("login.twoFA").replace("***", loginResponse.data.mobile)
        );
        if (multiFactorCode != null) {
          const deviceToken = window.localStorage.getItem("deviceToken");
          const multiFAResponse = await this.$http.post("auth/2fa", {
            twoFACorrelationId: loginResponse.data.twoFACorrelationId,
            mobileVerifCode: multiFactorCode,
            deviceToken,
          });
          window.localStorage.setItem(
            "token",
            multiFAResponse.data.accessToken
          );
          this.$router.push({ name: "business" });
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>